import React from "react";

export default function MarketoForm(props) {
  const [module, setModule] = React.useState({});

  React.useEffect(() => {
    import("./MarketoForm").then((module) => {
      setModule(module);
    });
  }, []);

  const Component = module.default;

  return Component ? <Component {...props} /> : null;
}
