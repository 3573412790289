import React from "react";

import Heading from "../Heading";
import Link from "../Link";
import Text from "../Text";
import Box from "../Box";
import Image from "../Image";
import Avatar from "../Avatar";

export default function ArticleThumbnail({
  to,
  title,
  subtitle,
  media,
  avatar,
  tag,
  sizes,
  width,
  loading,
  sx,
  ...otherProps
}) {
  return (
    <Link
      to={to}
      sx={{
        display: "flex",
        flexDirection: "column",
        "&:hover": {
          textDecorationColor: "transparent",
          ".img": {
            opacity: 0.5,
          },
        },
        ".img": {
          transition: (theme) => `all ${theme.transitions.fast}`,
        },
      }}
      {...otherProps}
    >
      <Box sx={{ position: "relative", flexShrink: 0 }}>
        {media.asset && (
          <Image
            className="img"
            fluid={{ ...media.asset.fluid, aspectRatio: 830 / 463 }}
            sizes={sizes}
            width={width}
            imgProps={{
              width: media.asset.metadata?.dimensions.width,
              height: media.asset.metadata?.dimensions.height,
            }}
            loading={loading}
            alt={media.alt}
            sx={{ borderRadius: "card" }}
          />
        )}
        {avatar?.asset && (
          <Avatar
            image={avatar.asset}
            sx={{
              transform: "translateY(-50%)",
              ml: 3,
              mb: -4,
              width: "15%",
            }}
          />
        )}
      </Box>
      <Heading
        as="h4"
        variant="h5"
        sx={{
          mt: 4,
          mb: 2,
        }}
      >
        {title}
      </Heading>
      {subtitle && <Text sx={{ color: "body", mt: "auto" }}>{subtitle}</Text>}
      {tag && (
        <Text
          variant="caps"
          sx={{
            color: "primary",
            border: "1px solid",
            borderColor: "primary",
            borderRadius: "default",
            py: 1,
            px: "8px",
            mt: 2,
            mr: "auto",
            lineHeight: 1,
          }}
        >
          {tag}
        </Text>
      )}
    </Link>
  );
}
