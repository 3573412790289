import React from "react";
import Box from "../Box";
import Heading from "../Heading";

import Renderer from "./Renderer";
import Author from "./Author";

export default function Article({
  title,
  body,
  date,
  author,
  sx,
  ...otherProps
}) {
  return (
    <Box
      as="article"
      variant="styles.article"
      sx={{ px: "gridGap", ...sx }}
      {...otherProps}
    >
      {author && (
        <Author
          sx={{
            position: [null, null, null, "absolute"],
            right: "gridGap",
            mt: [4, 4, 4, 0],
          }}
          name={author.name}
          image={author.image}
          date={date}
        />
      )}
      <Heading
        as="h1"
        variant="h3"
        sx={{
          mt: [4, 4, 4, 5],
        }}
      >
        {title}
      </Heading>
      <Renderer blocks={body} />
    </Box>
  );
}
