import { useCallback, useEffect, useState } from "react";

export default function useLocalStorage(key, initialValue = "") {
  const [value, setValue] = useState(
    () =>
      (typeof window !== "undefined" && window.localStorage.getItem(key)) ||
      initialValue
  );

  const setItem = (newValue) => {
    const jsonValue = JSON.stringify(newValue);

    setValue(jsonValue);
    window.localStorage.setItem(key, jsonValue);
  };

  useEffect(() => {
    const newValue = window.localStorage.getItem(key);
    if (value !== newValue) {
      setValue(newValue || initialValue);
    }
  }, [key, value, initialValue]);

  const handleStorage = useCallback(
    (event) => {
      if (event.key === key && event.newValue !== value) {
        setValue(event.newValue || initialValue);
      }
    },
    [initialValue, key, value]
  );

  useEffect(() => {
    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, [handleStorage]);

  return [value, setItem];
}
