import React from "react";
import GridRow from "../../commons/GridRow";
import Heading from "../../commons/Heading";
import Text from "../../commons/Text";
import Box from "../../commons/Box";
import Button from "../../commons/Button";
import MarketoForm from "../../commons/MarketoForm";
import useLocalStorage from "../../../utils/useLocalStorage";
import { LS_IS_SIGNED_UP_KEY } from "../../../utils/constants";

import { gradientBgVertical, fadeInAnim } from "../../../utils/styles";

export default function SignupBlock({
  reference: { title, subtitle, successMessage, submitBtn, marketoSnippet },
}) {
  const [isSignedUp, setSignedUp] = useLocalStorage(LS_IS_SIGNED_UP_KEY);
  const [isSubmitted, setSubmitted] = React.useState();

  React.useEffect(() => {
    if (isSignedUp) {
      setSubmitted(true);
    }
  }, [isSignedUp]);

  const handleSuccess = React.useCallback(() => {
    setSubmitted(true);
    setSignedUp(true);
  }, [setSignedUp]);

  const handleReset = React.useCallback(() => {
    setSubmitted(false);
    setSignedUp(false);
  }, [setSignedUp]);

  return (
    <GridRow as="section" sx={{ py: [5, "gridGap"], ...gradientBgVertical }}>
      <GridRow.Col gridColumn={["start / end", "start / span 5"]}>
        <Heading as="h2" sx={{ color: "inverted" }}>
          {title}
        </Heading>
        <Text as="p" sx={{ color: "inverted", mt: 4 }}>
          {subtitle}
        </Text>
      </GridRow.Col>
      <GridRow.Col
        gridColumn={["start / end", "9 / end"]}
        sx={{ position: "relative", mt: 3 }}
      >
        <MarketoForm
          codeSnippet={marketoSnippet}
          onSuccess={handleSuccess}
          submitLabel={submitBtn}
          sx={{
            transition: "all 300ms",
            ...(isSubmitted
              ? { visibility: "hidden", opacity: 0 }
              : {
                  visibility: "visible",
                  opacity: 1,
                }),
          }}
        />
        {isSubmitted && (
          <Box
            sx={(theme) => ({
              position: "absolute",
              top: ["auto", 0],
              bottom: ["10rem", 0],
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ...fadeInAnim,
            })}
          >
            <Box>
              <Heading
                as="p"
                variant="h3"
                sx={{
                  color: "inverted",
                  mb: 4,
                }}
              >
                {successMessage}
              </Heading>
              <Button
                variant="link"
                sx={{
                  color: "inverted",
                  textDecorationColor: "currentColor",
                  "&:hover": {
                    textDecorationColor: "transparent",
                  },
                }}
                onClick={handleReset}
              >
                I want to signup again
              </Button>
            </Box>
          </Box>
        )}
      </GridRow.Col>
    </GridRow>
  );
}
