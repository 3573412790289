import React from "react";

import RichTextRenderer from "../../RichTextRenderer";
import { Image } from "../../RichTextRenderer/sanityBlocks";

const getImageStyle = (style) => {
  switch (style) {
    case "wide":
      return { maxWidth: ["none", "80vw"] };
    case "fullwidth":
      return {
        maxWidth: "none",
        width: "auto",
        mx: (theme) => `-${theme.space.gridGap}`,
      };
    case "shrinked":
      return {
        maxWidth: ["60vw", "30vw"],
      };
    case "centered":
    default:
      return {
        maxWidth: null,
      };
  }
};

export const ImageSerializer = ({ node, ...otherProps }) => {
  return (
    <React.Fragment>
      <Image
        node={node}
        sx={{
          my: "gridGap",
          ...(node.attribution && { mb: 0, "& + p": { mb: "gridGap" } }),
          ...getImageStyle(node.style),
        }}
      />
      {node.attribution && (
        <RichTextRenderer blocks={node.attribution} {...otherProps} />
      )}
    </React.Fragment>
  );
};

export const Container = (props) => {
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default function ArticleRenderer({ ...otherProps }) {
  return (
    <RichTextRenderer
      serializers={{
        types: {
          imageArticle: ImageSerializer,
        },
        container: Container,
      }}
      {...otherProps}
    />
  );
}
