import React from "react";

import Box from "../Box";
import Image from "../Image";

export default function Avatar({ image, sx, ...otherProps }) {
  return (
    <Box sx={sx}>
      <Image
        fluid={{
          ...image.fluid,
          // override aspect ratio so image is always rounded
          aspectRatio: 1,
        }}
        alt={image.alt}
        sx={(theme) => ({
          borderRadius: "circle",
        })}
        {...otherProps}
      />
    </Box>
  );
}
