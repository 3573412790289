import React from "react";
import Flex from "../../Flex";
import Text from "../../Text";
import Image from "../../Image";

export default function Author({ name, image, date, sx }) {
  return (
    <Flex sx={{ display: "flex", ...sx }}>
      {image && image.asset && (
        <Image
          sx={{ borderRadius: "circle", width: [40, 80], height: [40, 80] }}
          fluid={image.asset.fluid}
        />
      )}

      <Flex
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          borderLeft: "1px solid",
          borderColor: "gray.2",
          pl: 3,
          ml: 3,
        }}
      >
        <Text as="address">{name}</Text>
        <Text
          as="time"
          dateTime={date.value}
          sx={{ variant: "text.caps", color: "primary", mt: 1 }}
        >
          {date.label}
        </Text>
      </Flex>
    </Flex>
  );
}
