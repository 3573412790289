import React from "react";

import Hero from "../../commons/Hero";
import Image from "../../commons/Image";
import Page from "../../commons/Page";
import SignupBlock from "../../blocks/SignupBlock";

export default function BasePage({
  title,
  description,
  page,
  pathname,
  banner,
  children,
  ...otherProps
}) {
  const signupBlock = page.blocks.find(
    (block) => block._type === "signupBlock"
  );
  return (
    <Page
      title={title}
      description={description}
      pathname={`${page.slug.current}/${pathname}`}
      banner={banner.asset.fluid.src}
      {...otherProps}
    >
      <Hero>
        <Hero.Background>
          <Image
            sx={{ height: "100%" }}
            fluid={banner.asset.fluid}
            loading="eager"
            alt={banner.alt}
          />
        </Hero.Background>
      </Hero>
      {children}
      {signupBlock && <SignupBlock {...signupBlock} />}
    </Page>
  );
}
